import React, { useState, useEffect } from 'react';
import cookies from 'browser-cookies';

import SunIcon from './icons/sun-icon';
import styles from './dark-mode-button.module.css';

const initialMode = typeof document !== 'undefined' && cookies.get('dark-mode') == 'TRUE';

const useToggle = (initialState = false) => {
  const [state, setState] = useState(!!initialState);
  const toggle = () => setState(!state);

  return [state, toggle];
}

const DarkModeButton = (props) => {
  const [isDark, toggleDark] = useToggle(initialMode);
  useEffect(() => {
    var root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
    root.setAttribute( 'class', isDark ? 'dark' : '' );
    cookies.set('dark-mode', isDark ? 'TRUE' : 'FALSE' );
  }, [isDark]);

  return (
    <div className={styles.root} onClick={toggleDark} >
      <SunIcon />
    </div>
  )
}

export default DarkModeButton;
