import _ from 'lodash/fp';
import React, { useRef, useState } from 'react';

import styles from './move.module.css';

const Move = (props) => {
  const {
    trigger,
    name,
    source,
    html,
  } = props;

  const [showContent, setShowContent] = useState(false);
  const toggleShowContent = () => {
    setShowContent(!showContent);
  }

  const sidetext = (source ? `${name} (${source})` : name);

  return (
    <article className={styles.root}>
      <header onClick={toggleShowContent}>
        <h2>{trigger}...</h2>
        <cite>{sidetext}</cite>
      </header>
      {showContent && (
        <div className={styles.content}>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <div className={styles.spacer} />
        </div>
      )}
    </article>
  );
}

export default Move;
