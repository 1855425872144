import _ from 'lodash/fp';
import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Move from "../components/move";
import DarkModeButton from '../components/dark-mode-button';
import SEO from "../components/seo"


const moves = (data) => _.map(
  (edge) => {
    const key = edge.node.name;
    const { frontmatter, html } = _.get('node.childMarkdownRemark', edge) || {};

    return {
      ...frontmatter,
      html,
      key,
    };
  },
  data.moves.edges
);


const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <DarkModeButton />
      {_.map(
        (move) => (<Move key={move.key} {...move} />),
        moves(data)
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    moves: allFile(
      filter: { sourceInstanceName: { eq: "moves" } }
      sort: {
        fields: [
          childMarkdownRemark___frontmatter___rank,
          childMarkdownRemark___frontmatter___name,
          name
        ],
        order: [DESC, ASC, ASC]
      }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              name,
              trigger,
              source,
            },
            html,
          }
          name,
        }
      }
    }
  }
`;

export default IndexPage;
